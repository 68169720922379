<template>
 <div class="register">
  <el-card class="box-card" shadow="never" body-style="padding: 0;">
    <Trial style="background-color: #fff;"/>
    <p class="login-text" @click="$router.push(`/login?redirect=${redirect}`)">已有账号？立即登录</p>
  </el-card>
 </div>
</template>
<script>
import Trial from '@/components/trial'
export default {
  components: { Trial },
  data() {
    return {
      redirect: this.$route.query?.redirect,
    }
  }
}
</script>
<style lang='scss' scoped>
.register {
  width: Translate(1200px);
  margin: Translate(24px) auto 0;
}
.login-text {
  position: relative;
  top: Translate(-60px);
  margin-top: Translate(29px);
  font-size: Translate(14px);
  color: #4088eb;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}
</style>
